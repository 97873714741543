import { type THomepageSRP } from "@/commons/types/product"
import { ProductType, SearchProductStrategy } from "@/federatedGql/graphql"

export const DEFAULT_REGION_CODE = "31.71"

export const HOMEPAGE_SRP: THomepageSRP[] = [
  {
    strategy: SearchProductStrategy.Tkdn,
    title: "Produk Bersertifikat TKDN",
    showOnlyOnLogin: false,
    srpRoute: `/search?page=1&source=homepage.${SearchProductStrategy.Tkdn.toLowerCase()}`,
  },
  {
    strategy: SearchProductStrategy.TkdnUmkk,
    title: "Produk dalam Negeri dari UMKK",
    showOnlyOnLogin: false,
    srpRoute: `/search?page=1&source=homepage.${SearchProductStrategy.TkdnUmkk.toLowerCase()}`,
  },
  {
    strategy: SearchProductStrategy.Local,
    title: "Produk dari Daerahmu",
    showOnlyOnLogin: true,
    srpRoute: `/search?page=1&source=homepage.${SearchProductStrategy.Local.toLowerCase()}`,
  },
]

export const PRODUCT_TYPE: Record<string, string> = {
  [ProductType.Physical]: "Barang",
  [ProductType.Digital]: "Digital",
  [ProductType.Service]: "Jasa",
}
