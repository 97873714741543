import { type TAddress } from "gtp-ui/src/address/modal-add-address/type"

import { mapRegion } from "./mapRegion"
import { type TTempAddress } from "../../commons/types/address"
import capitalize from "@/utils/capitalize"

export default function convertTempAddressIntoAddress(
  tempAddress: TTempAddress
): TAddress {
  return {
    label: tempAddress.label,
    name: tempAddress.receiverName,
    phone: tempAddress.phoneNumber,
    province: mapRegion(tempAddress.province),
    city: mapRegion(tempAddress.city),
    district: mapRegion(tempAddress.district),
    village: mapRegion(tempAddress.village),
    postalCode: {
      areaCode: "",
      id: "",
      name: tempAddress.postalCode,
    },
    googleMaps: {
      ...(!!tempAddress.latitude &&
        !!tempAddress.longitude && {
          latLng: {
            lat: tempAddress.latitude,
            lng: tempAddress.longitude,
          },
        }),
      addressName: tempAddress.formattedAddress,
      placeId: tempAddress.id,
      addressSecondary: `Kecamatan ${capitalize(
        tempAddress.district.name
      )}, ${capitalize(tempAddress.city.name)}, ${capitalize(
        tempAddress.province.name
      )}`,
      districtAreaCode: tempAddress.district.areaCode,
    },
    address: tempAddress.formattedAddress,
    notes: tempAddress.notes || undefined,
    isPrimaryAddress: tempAddress.isMainAddress,
  }
}
