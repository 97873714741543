import { graphql } from "@/federatedGql/gql"

export const GET_TRACE_PARENT_REGION = graphql(`
  query getTraceParentRegion($areaCodes: [String!]) {
    getTraceParentRegion(query: { areaCodes: $areaCodes }) {
      items {
        provinceAreaCode
        provinceId
        provinceName
        cityAreaCode
        cityId
        cityName
        districtAreaCode
        districtId
        districtName
        villageAreaCode
        villageId
        villageName
        villagePostalCode
      }
    }
  }
`)

export const GET_PERSONA_NON_PENYEDIA = graphql(`
  query getPersonaNonpenyediaByID($id: String!) {
    getPersonaNonpenyediaByID(id: $id) {
      ... on PersonaNonPenyedia {
        __typename
        satker {
          namaSatker
          jenisSatker
          npwp
          alamat
        }
        klpd {
          kodeKlpd
          namaKlpd
        }
        persona {
          unit
          penyediaJabatan
          isTest
        }
        personaShippingAddress {
          __typename
          id
          fullAddress
          isMainAddress
          label
          latitude
          longitude
          notes
          phoneNumber
          postalCode
          receiverName
          villageAreaCode
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_PERSONA_SHIPPING_ADDRESS = graphql(`
  query getPersonaShippingAddress($id: String!) {
    getPersonaNonpenyediaByID(id: $id) {
      ... on PersonaNonPenyedia {
        __typename
        personaShippingAddress {
          __typename
          id
          fullAddress
          isMainAddress
          label
          latitude
          longitude
          notes
          phoneNumber
          postalCode
          receiverName
          villageAreaCode
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)
