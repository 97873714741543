import { graphql } from "@/federatedGql"

export const SEARCH_PRODUCT = graphql(`
  query searchProducts($input: SearchProductInput!) {
    searchProducts(input: $input) {
      ... on ListSearchProductResponse {
        total
        perPage
        currentPage
        lastPage
        aggregations {
          ... on CategoryAllLevelAggregations {
            __typename
            categories {
              count
              id
              name
              children {
                count
                id
                name
                curationEnabled
                children {
                  count
                  id
                  name
                  curationEnabled
                }
              }
            }
          }
        }
        items {
          id
          type
          isActive
          images
          isPreOrder
          isRegionPrice
          isSellerUMKK
          labels
          isWholesale
          defaultPrice
          defaultPriceWithTax
          createdAt
          maxPrice
          maxPriceWithTax
          minPrice
          minPriceWithTax
          ppnBmPercentage
          ppnPercentage
          tkdn {
            value
            bmpValue
            tkdnBmp
            status
          }
          location {
            name
            regionCode
            child {
              name
              regionCode
              child {
                name
                regionCode
                child {
                  name
                  regionCode
                }
              }
            }
          }
          name
          stockAvailability
          stockAccumulation
          sellerName
          sellerId
          score
          scoreDetail {
            keywordScore
            locationScore
            priceScore
            ratingScore
            tkdnScore
            umkkScore
            unitSoldScore
          }
          unitSold
          username
          slug
          rating {
            count
            average
          }
        }
      }
      ... on GenericError {
        __typename
        reqId
        message
        code
      }
    }
  }
`)

export const GET_ALL_REGION_PROVINCE = graphql(`
  query getAllRegionProvince($regionLevel: RegionLevel) {
    getAllRegionV2(query: { filter: { level: $regionLevel } }) {
      ... on RegionAllList {
        items {
          name
          level
          id
          parentId
          areaCode
        }
        __typename
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_ALL_REGION_CITY = graphql(`
  query getAllRegionCity(
    $regionLevel: RegionLevel! = PROVINCE
    $parentAreaCodes: [String!]
  ) {
    getAllRegionV2(
      query: {
        filter: { level: $regionLevel, parentAreaCodes: $parentAreaCodes }
      }
    ) {
      ... on RegionAllList {
        items {
          name
          level
          id
          parentId
          areaCode
          parentAreaCode
        }
        __typename
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_ALL_MINIFIED_CATEGORY = graphql(`
  query getAllMinifiedCategory($input: AllMinifiedProductCategoryInput!) {
    allMinifiedProductCategory(input: $input) {
      ... on AllMinifiedProductCategories {
        __typename
        items {
          id
          level
          name
          productType
          icon
          children {
            id
            level
            name
            productType
            icon
            children {
              id
              level
              name
              productType
              icon
            }
          }
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_STORE_INFORMATION = graphql(`
  query getStoreInformation($filter: StoreInformationFilterInput!) {
    storeInformation(filter: $filter) {
      ... on StoreInformations {
        __typename
        items {
          createdAt
          description
          imageId
          imageUrl
          name
          sellerId
          updatedAt
        }
      }
      ... on Error {
        __typename
        reqId
        message
        code
      }
    }
  }
`)

export const GET_COMPANY_PUBLIC = graphql(`
  query getCompanyPublic($filter: CompanyPublicFilter!) {
    companyPublic(filter: $filter) {
      ... on CompanyPublicResult {
        __typename
        companies {
          bentukUsaha
          bidangIndustri
          companyAddresses {
            fullAddress
            id
            postalCode
            villageAreaCode
          }
          id
          institusiId
          isUkm
          isUmkk
          jenisPerusahaan
          name
          oss {
            project {
              createdAt
              createdBy
              description
              id
              kbli {
                createdAt
                createdBy
                id
                izin {
                  code
                  createdAt
                  createdBy
                  expired
                  id
                  izinId
                  number
                  type
                  typeOss
                  updatedAt
                  updatedBy
                }
                kbli
                name
                updatedAt
                updatedBy
              }
              projectId
              riskCode
              updatedAt
              updatedBy
            }
          }
          shippingAddresses {
            id
            postalCode
            villageAreaCode
            regionDetail {
              cityName
              districtName
              provinceName
              villageName
            }
          }
          skalaUsaha
          slug
          status
          website
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_SEARCH_SELLER = graphql(`
  query getSearchSeller($input: SearchSellerInput!) {
    searchSeller(input: $input) {
      ... on SearchSellerResponse {
        total
        perPage
        currentPage
        lastPage
        items {
          imageUrl
          isUMKK
          location {
            name
            regionCode
            child {
              name
              regionCode
              child {
                name
                regionCode
                child {
                  name
                  regionCode
                }
              }
            }
          }
          name
          slug
          transactionCount
        }
      }
      ... on GenericError {
        __typename
        code
        message
        reqId
      }
    }
  }
`)

export const GET_COMPANY_PUBLIC_BLACKLIST = graphql(`
  query getCompanyPublicBlacklist($filter: CompanyPublicFilter!) {
    companyPublic(filter: $filter) {
      ... on CompanyPublicResult {
        __typename
        companies {
          blacklist {
            status
            startDate
            endDate
          }
        }
      }
      ... on GenericError {
        __typename
        reqId
        message
        code
      }
    }
  }
`)
