import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

import { type TTempAddress } from "@/commons/types/address"

type TSelectedBuyerAddressStore = {
  selectedBuyerAddress?: TTempAddress | undefined
  updateSelectedBuyerAddress: (data: TTempAddress | undefined) => void
  resetSelectedBuyerAddress: () => void
}

export const useSelectedBuyerAddressStore =
  create<TSelectedBuyerAddressStore>()(
    devtools(
      persist(
        (set) => ({
          selectedBuyerAddress: undefined,
          updateSelectedBuyerAddress: (data: TTempAddress | undefined) =>
            set(() => ({ selectedBuyerAddress: data })),
          resetSelectedBuyerAddress: () =>
            set(() => ({ selectedBuyerAddress: undefined })),
        }),
        {
          name: "selected-buyer-address-storage",
        }
      )
    )
  )
