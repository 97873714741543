import { IS_DEVELOPMENT, IS_PREPRODUCTION, IS_STAGING } from "shared-utils"

export const NEW_ORDER_ADDENDUM = "ORDER-ADDENDUM-2"
export const LIMIT_ALL_TRANSACTION_VALUE = "ORDER-LIMIT_ALL_TRANSACTION_VALUE"
export const PAY_SIMULATION_BUTTON = "PAYMENT-PAY_SIMULATION_BUTTON"
export const REMOVE_REGION_FORM_ADDRESS = "LOGISTIC-REMOVE_REGION_FORM_ADDRESS"
export const VALIDATE_LOGISTIC_KILL_SWITCH =
  "PRODUCT-VALIDATE_LOGISTIC_KILL_SWITCH"
export const SELLER_ADDENDUM = "ORDER-ALLOW_SELLER_TO_PROPOSE_ADDENDUM"
export const PAYMENT_COA_FLOW = "PAYMENT_COA_FLOW"
export const HIDE_CANCEL_ORDER_BUTTON = "ORDER-HIDE_CANCEL_ORDER_BUTTON"
export const VALIDATION_RUP_PAYMENT_METHOD_PEMDA =
  "PAYMENT-VALIDATION_RUP_PEMDA"
export const NEW_CURATION_FLOW_RELEASE = "PRODUCT-NEW_CURATION_FLOW_RELEASE"
export const ADDENUM_QTY_ZERO = "ORDER-ADDENDUM_QUANTITY_ZERO"
export const ENABLE_MINICOM_FEATURE =
  "BUYER-MINI-COMPETITION-ENABLE_MINICOM_FEATURE"
export const ORDER_TERMINATION = "ORDER-ALLOW_INTERNAL_TO_TERMINATE_ORDER"
export const SHOW_BYPASS_SAKTI_SIMULATION = "PAYMENT-BYPASS_SAKTI_INTEGRATION"
export const ORDER_RATING_REVIEW = "PRODUCT-ORDER_RATING_REVIEW"
export const USE_GOOGLE_MAP_VERSION_2 = "USE_GOOGLE_MAP_VERSION_2"
export const BLACKLIST_CONSUMER_PLATFORM_INTEGRATION =
  "BLACKLIST-BUYER_CONSUMER_PLATFORM_INTEGRATION"
export const VALIDATION_BUTTON_IS_LOCK_PAYMENT =
  "PAYMENT-VALIDATION_IS_LOCK_PAYMENT"
export const MULTISHIPMENT = "MULTISHIPMENT"

export const IS_PREPROD_OR_STAGING =
  IS_STAGING || IS_PREPRODUCTION || IS_DEVELOPMENT
