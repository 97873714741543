"use client"
import { useRouter } from "next/navigation"
import { ChevronRight } from "react-feather"
import { getUserInfo } from "shared-utils/authentication"

import CarouselProducts from "@/components/carousel-products"
import Skeleton from "@/components/skeleton"

import ProductEmptyState from "./ProductEmptyState"
import { HOMEPAGE_SRP } from "@/commons/constants/product"
import { type THomepageSRP } from "@/commons/types/product"
import useAddressSettings from "@/hooks/address/useAddressSettings"
import useSearchProduct from "@/hooks/product/useSearchProduct"
import { useSelectedBuyerAddressStore } from "@/store/useSelectedBuyerAddressStore"

type THomepageSRPList = THomepageSRP
const HomepageSRPList = ({
  strategy,
  title,
  showOnlyOnLogin,
  featureFlags,
}: THomepageSRPList) => {
  const router = useRouter()
  const session = getUserInfo()
  const { selectedBuyerAddress } = useSelectedBuyerAddressStore()
  const { addressList } = useAddressSettings()

  const buyerAddress = addressList.find((dtAddress) => dtAddress.isMainAddress)
  const villageAreaCode =
    selectedBuyerAddress?.village.areaCode || buyerAddress?.village.areaCode

  const {
    data: products,
    isFetching,
    error,
  } = useSearchProduct({
    filter: {
      input: {
        filter: {
          strategy,
          regionCode:
            showOnlyOnLogin && !!villageAreaCode ? villageAreaCode : undefined,
        },
        pagination: {
          page: 1,
          perPage: 12,
        },
      },
    },
    options: {
      enabled:
        !showOnlyOnLogin ||
        (showOnlyOnLogin && !!session?.user?.id && !!villageAreaCode),
    },
  })

  const handleShowAllProducts = () => {
    router.push(
      HOMEPAGE_SRP.find((item) => item.strategy === strategy)?.srpRoute || ""
    )
  }

  if (showOnlyOnLogin && !session?.user?.id) {
    return null
  }

  if (isFetching) {
    return (
      <section className="flex flex-col gap-4">
        <Skeleton
          width="w-24"
          height="h-9"
          variant="rounded"
          bgColor="bg-tertiary50"
        />
        <div className="grid grid-cols-6 gap-3">
          {Array.from({ length: 6 }).map((_, idx) => (
            <Skeleton
              key={idx}
              width="w-full"
              height="h-[344px]"
              variant="rounded"
              bgColor="bg-tertiary50"
            />
          ))}
        </div>
      </section>
    )
  }

  if (error) {
    return (
      <h2 className="text-heading-h6 text-tertiary500">
        Telah terjadi kesalahan, coba beberapa saat lagi
      </h2>
    )
  }

  return (
    <section>
      <div className="flex items-center gap-4">
        <h2 className="text-heading-h5-respon  text-tertiary500">{title}</h2>
        {products?.items && (
          <button
            id="product-carousel-seeall-btn"
            className="text-caption-sm-semibold flex items-center gap-2 text-secondary500"
            onClick={handleShowAllProducts}
          >
            Lihat Semua <ChevronRight size={16} />
          </button>
        )}
      </div>

      {!products?.items && <ProductEmptyState />}

      <div className="mt-4">
        <CarouselProducts
          items={products?.items ?? []}
          featureFlags={featureFlags}
          strategy={strategy}
        />
      </div>
    </section>
  )
}

export default HomepageSRPList
