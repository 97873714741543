export const regionPriceTooltipInfo = `
<div class="flex w-1/2 flex-col gap-2">
  <h4 class="text-sm font-semibold">Produk dengan harga zonasi</h4>
  <p class="text-xs font-normal">
    Harga zonasi berubah tergantung
    <br />
    zona alamat pembeli, biaya
    <br />
    pengiriman, dan pajak yang
    <br />
    berbeda di setiap lokasi.
  </p>
</div>
`
