import { cx } from "class-variance-authority"
import { Info } from "react-feather"
import ProductFallbackImage from "shared-assets/images/broken-product-image.webp"
import { Tooltip, ImageWithFallback, RatingStars } from "shared-ui"
import {
  generateBlurDataUrl,
  toLowerCaseLocation,
  formatIntoCurrency,
} from "shared-utils"

import { regionPriceTooltipInfo } from "./constants"
import { type TProduct } from "./type"
import { productTypeLabel } from "./utils/handleProductTypeLabel"
import { ProductTkdnStatus } from "@/federatedGql/graphql"
import { sortProductLabelsByName } from "@/utils/common"
import { formatSold } from "@/utils/formatSold"
import { roundUpDigits } from "@/utils/number"
import type { SearchProductStrategy } from "@/federatedGql/graphql"

type TProductCardProps = {
  product: TProduct
  href?: string
  isRatingReviewEnabled?: boolean
  strategy?: SearchProductStrategy
}

const ProductCard = ({
  product,
  isRatingReviewEnabled = false,
  strategy,
}: TProductCardProps) => {
  const isOutOfStock = product?.stockAccumulation === 0
  const stockLabel = isOutOfStock ? "Stok Habis" : "Pre Order"
  const productLabels =
    product?.labels?.filter((label) => label !== "Pre Order") || []
  const isPriceAfterTax = (product.ppnPercentage || product.ppnBmPercentage) > 0

  const showProductRating = isRatingReviewEnabled && !!product.rating

  const renderProductLabels = () => {
    const excludedLabels = [
      "Inovasi",
      "Ramah Lingkungan",
      "PPN",
      "PPNBM",
      "Verified",
    ]
    return sortProductLabelsByName(productLabels, "PDN")
      .filter((label) => !excludedLabels.includes(label))
      .map((label, idx) => (
        <div
          key={`label_${idx}`}
          className="flex items-center rounded-2 bg-tertiary25 px-1 font-semibold text-tertiary500"
        >
          {label}
          {label === "Harga Zonasi" && <Info size={12} className="ml-1" />}
        </div>
      ))
  }

  const getProductPrice = () => {
    const defaultPrice = isPriceAfterTax
      ? product.defaultPriceWithTax
      : product.defaultPrice

    if (product.isRegionPrice) {
      const minPrice = isPriceAfterTax
        ? product.minPriceWithTax
        : product.minPrice
      const maxPrice = isPriceAfterTax
        ? product.maxPriceWithTax
        : product.maxPrice

      return formatIntoCurrency({
        value: defaultPrice,
        range: { min: minPrice, max: maxPrice },
      })
    }

    return formatIntoCurrency({ value: defaultPrice })
  }

  const productPrice = getProductPrice()

  const renderProductPrice = () => (
    <div className="w-fit truncate text-sm font-bold text-tertiary500">
      {!product.isRegionPrice && productPrice}
      {product.isRegionPrice && (
        <span className="flex items-center gap-2">
          {productPrice}
          <Tooltip title={regionPriceTooltipInfo}>
            <Info className="min-w-min text-tertiary400" size={18} />
          </Tooltip>
        </span>
      )}
    </div>
  )

  return (
    <div className="flex h-full">
      <div className="group/card bg-white shadow-lg relative flex-1 overflow-hidden rounded-4 border border-tertiary50 text-xs font-normal">
        <div className="relative w-full pt-[100%]">
          <ImageWithFallback
            key={`product-image-${product.slug}`}
            alt={product.name}
            className={cx(isOutOfStock && "brightness-50")}
            fallbackSrc={ProductFallbackImage}
            fill
            loading="lazy"
            placeholder="blur"
            quality={product?.images?.[0] ? 70 : 100}
            src={product.images?.[0] ?? ProductFallbackImage}
            style={{ objectFit: "cover" }}
            blurDataURL={generateBlurDataUrl(187, 188)}
          />

          <div className="rounded absolute left-0 top-0 flex gap-2 bg-transparent p-2">
            <div className="rounded-2 bg-information25 px-2 py-1 text-xs font-semibold text-information500">
              {productTypeLabel(product.type)}
            </div>
            {(product.isPreOrder || isOutOfStock) && (
              <div className="rounded-2 bg-tertiary500 p-1 text-xs font-semibold text-warning25">
                {stockLabel}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-1 p-3">
          <div className="line-clamp-2 text-sm text-tertiary500">
            {product.name}
          </div>
          <Tooltip
            withPortal
            title={isPriceAfterTax ? "Termasuk pajak" : "Tidak Kena Pajak"}
          >
            {renderProductPrice()}
          </Tooltip>
          {product?.tkdn?.status === ProductTkdnStatus.Approved &&
            !product.labels?.includes("Import") && (
              <div>
                <span className="rounded-2 bg-success700 px-1 font-semibold text-tertiary25">
                  TKDN+BMP {roundUpDigits(product.tkdn.tkdnBmp)}%
                </span>
              </div>
            )}

          <div className="flex flex-wrap gap-1">{renderProductLabels()}</div>
          <div className="flex gap-1">
            {product.isSellerUMKK && (
              <div className="bg-information25 px-1 font-semibold text-information500">
                UMKK
              </div>
            )}
            <div className="h-4 cursor-pointer overflow-hidden text-tertiary300">
              <span className="block truncate capitalize transition-transform duration-300 group-hover/card:-translate-y-4">
                {toLowerCaseLocation(product.location.child.name)}
              </span>
              <span className="block truncate transition-transform duration-300 group-hover/card:-translate-y-4">
                {product.sellerName}
              </span>
            </div>
          </div>
          <div className="text-xs text-tertiary300">
            {showProductRating ? (
              <div className="mt-1 flex items-center gap-1">
                <div className="flex items-center">
                  <RatingStars
                    id={`product-card-${strategy ?? ""}-${product.id}`}
                    size="tiny"
                    starCount={1}
                    isEditable={false}
                    withDescription={false}
                    score={1}
                  />
                  <div className="text-caption-sm-regular text-tertiary300">
                    {product.rating?.average.toFixed(1)}
                  </div>
                </div>
                <div className="text-tertiary200">•</div>
                <div className="text-caption-sm-regular">
                  {formatSold(product.unitSold)}
                </div>
              </div>
            ) : (
              <div className="ml-1">{formatSold(product.unitSold)}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductCard
